/* eslint-disable max-len */

export const keys = {
  pageSubtitle: "",
  pageTitle: "Every childcare center,\nall in one place.",

  pdoSection: {
    paragraph1:
      "We showcase over 90K early education facilities, including childcare centers, nurseries, preschools, and private schools.",
    paragraph2:
      "Each facility is accompanied by an interactive profile containing in-depth operational details about the service provider.",
    paragraph3:
      "Additionally, all facility profiles are powered by our smart-chat capability, giving parents an easy way to submit enrollment questions and book school tours.",
    title: "About us",
  },

  aboutBlock: {
    paragraph1:
      "We are the premier marketplace for connecting organizations with licensed and vetted childcare facilities for the purpose of extending childcare benefits to their families.",
    paragraph2: "Parent Pass is 100% a plug & play.",
    paragraph3:
      "Its implementation does not require any construction, the hiring of staff, any investment in technology or any administrative overhead on the part of the sponsoring organization.",
    title: "About Parent Pass",
  },

  howItWorksBlock: {
    paragraph1:
      "At the heart of the Parent Pass Program is the familiar enrollment model, but with a twist.\n\nWith Parent Pass, instead of the family, it is the organization that secures a dedicated childcare service schedule, with the resulting benefits passed through to a pool of beneficiaries, such as company employees and/or customers. The choice is yours!",
    title: "How It Works",
  },

  ourServicesBlock: {
    item1: "work with your team to gather service requirements",
    item2:
      "scout for best-fit childcare facilities in your designated service area(s)",
    item3: "provide a curated list of service providers for you to select from",
    item4: "ring fence the required childcare service schedules",
    item5: "execute service agreements with your selected providers",
    item6:
      "partner with your organization to help market and promote the service (optional)",
    item7: "reimburse childcare facilities for services provided",
    item8:
      "support parents with a fully automated, online booking flow (+ dedicated customer support)",
    item9:
      "deliver monthly activity reports to the designated PoC within your organization (optional)",
    paragraph1:
      "When your organization partners with us to implement its Parent Pass, we will:",
    title: "Our Services",
  },

  faqBlock: {
    item1: "improve employee recruitment, retention, and loyalty",
    item2:
      "provide an exclusive, life-improving perk, one that your customers cannot access otherwise",
    item3:
      "make it incredibly convenient for prospective and existing customers to do business with you",
    item4:
      "boost sale volumes and increase the sales efficiency of your team (i.e., close deals faster)",
    item5: "facilities brand loyalty and generate publicity",
    item6:
      "differentiate your company. improving its competitiveness and marketability",
    item7:
      "eliminate cost and liability associated with providing onsite childcare services",
    title: "Parent Pass Benefits",
  },
};
