import React, { useState, forwardRef } from "react";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { InputField, InputFieldProps } from "./Input";

const PasswordInputComponent = forwardRef(
  (
    { label = "Password", ...props }: InputFieldProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <InputField
        {...props}
        ref={ref}
        label={label}
        type={showPassword ? "text" : "password"}
        name="password"
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword((prevState) => !prevState)}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    );
  },
);

PasswordInputComponent.displayName = "PasswordInput";

export default PasswordInputComponent;
