export const keys = {
  exportButtonLabel: "Export to csv",
  reservationsTable: {
    columns: {
      id: "ID",
      status: "Status",
      serviceDate: "Service date",
      serviceDay: "Service day",
      timeFromSchools: "From",
      timeFromCorporations: "Appointment time",
      timeTo: "To",
      dateBooked: "Date booked",
      dateCancelled: "Date cancelled",
      coPayment: "Co-payment",
      facilityName: "Facility name",
      facilityCity: "City",
      facilityState: "State",
      facilityZip: "Zip code",
      parentFirstName: "Parent first name",
      parentLastName: "Parent last name",
      parentPhone: "Phone #",
      parentEmail: "Contact email",
      childGender: "Child gender",
      childFirstName: "First name",
      childLastName: "Last name",
      childBirthday: "DoB",
      childPhoto: "Photo",
      childImmunizations: "Immunizations",
      childMedicalHistory: "Medical history",
      childMedicalInformation: "About",
    },
  },
};
