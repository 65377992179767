/* global ProtoExtends */
import React, { forwardRef } from "react";
import clsx from "clsx";
import isNil from "lodash/isNil";

import InputAdornment from "@material-ui/core/InputAdornment";
import MaterialInput, { InputProps } from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

import useStyles from "./styles";
import Typography from "@ui-kit/Typography";

export type InputFieldProps = ProtoExtends<
  InputProps,
  {
    maxLength?: number;
    maxChars?: number;
    maxValue?: number;
    variant?: "number" | "integer";
    selectOnFocus?: boolean;
    inputClasses?: InputProps["classes"];
    formControlClasses?: FormControlProps["classes"];
    label?: string;
    helperText?: string;
    error?: string | React.ReactElement;
    noZero?: boolean;
    useCurrencyAdornment?: boolean;
  }
>;

export const InputField = forwardRef(
  (
    {
      noZero,
      name,
      selectOnFocus,
      maxValue,
      maxLength,
      maxChars,
      useCurrencyAdornment,
      ...props
    }: InputFieldProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const classes = useStyles();

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      const { variant, onChange } = props;
      let { value } = event.target;

      if (variant === "number") {
        const re = new RegExp("^\\d*(\\.\\d{0,2})?$");

        if (!re.test(value)) {
          return;
        }

        if (maxValue && parseInt(value, 10) > maxValue) {
          return;
        }
      }

      if (variant === "integer") {
        if (!/^(0|[1-9]\d*)?$/.test(value)) {
          return;
        }
      }

      if (noZero && value === "0") {
        value = "";
      }

      if (maxChars && value.length > maxChars) {
        return;
      }

      if (maxLength && value.length > maxLength) {
        value = value.slice(0, maxLength);
      }

      onChange?.({
        ...event,
        target: {
          ...event.target,
          value,
        },
      });
    }

    function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
      const { onBlur } = props;

      onBlur?.(event);
    }

    function handleFocus(
      event: React.FocusEvent<HTMLDivElement & HTMLInputElement>,
    ) {
      const { onFocus } = props;
      const { target } = event;

      if (selectOnFocus) {
        target.setSelectionRange(0, target.value.length);
      }

      onFocus?.(event);
    }

    const {
      label,
      error,
      inputClasses,
      variant,
      formControlClasses,
      value,
      helperText,
      required,
      startAdornment,
      ...rest
    } = props;

    if (variant === "number" || variant === "integer") {
      rest.type = "tel";
    }

    return (
      <FormControl
        fullWidth
        error={Boolean(error)}
        margin="normal"
        classes={{
          ...formControlClasses,
        }}
        className={clsx({
          "form-error": !!error,
        })}
      >
        {Boolean(label) && (
          <InputLabel>
            <Typography variant="inherit" required={required}>
              {label}
            </Typography>
          </InputLabel>
        )}
        <MaterialInput
          data-test={name ? `${name}-field` : undefined}
          {...rest}
          value={isNil(value) ? "" : value}
          fullWidth
          name={name}
          inputRef={ref}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          classes={{
            root: classes.materialInputRoot,
            ...inputClasses,
          }}
          startAdornment={
            useCurrencyAdornment ? (
              <InputAdornment position="start">$</InputAdornment>
            ) : (
              startAdornment
            )
          }
        />
        {typeof error !== "undefined" && (
          <FormHelperText className="error">{error}</FormHelperText>
        )}
        {Boolean(helperText) && !Boolean(error) && (
          <FormHelperText className={classes.helperText}>
            {helperText}
          </FormHelperText>
        )}
        {Boolean(maxChars) && typeof value === "string" && (
          <FormHelperText className={classes.charsCounter}>
            {value.length}/{maxChars}
          </FormHelperText>
        )}
      </FormControl>
    );
  },
);

InputField.defaultProps = {
  onBlur: () => null,
  onChange: () => null,
  onFocus: () => null,
  type: "text",
};
InputField.displayName = "InputField";
