/* global ProtoExtends */
import React, { forwardRef } from "react";
import clsx from "clsx";

import MaterialOutlinedInput, {
  OutlinedInputProps,
} from "@material-ui/core/OutlinedInput";
import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@ui-kit/Typography";

import useStyles from "./styles";

export type IProps = ProtoExtends<
  OutlinedInputProps,
  {
    maxChars?: number;
    inputClasses?: OutlinedInputProps["classes"];
    formControlClasses?: FormControlProps["classes"];
    error?: string | React.ReactElement;
    noZero?: boolean;
    variant?: "number" | "integer";
    maxLength?: number;
    classes?: Partial<{
      error: string;
    }>;
  }
>;

const OutlinedInputComponent = forwardRef(
  (
    {
      variant,
      noZero,
      maxLength,
      name,
      classes,
      ...props
    }: React.PropsWithChildren<IProps>,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const defaultClasses = useStyles();

    function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
      const { onBlur } = props;

      onBlur?.(event);
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      const { onChange, maxChars } = props;
      let { value } = event.target;

      if (variant === "number") {
        let re = new RegExp("^\\d*(\\.\\d{0,2})?$");

        if (maxLength) {
          re = new RegExp(`^\\d{0,${maxLength}}(\\.\\d{0,2})?$`);
        }

        if (!re.test(value)) {
          return;
        }
      }

      if (variant === "integer") {
        let re = new RegExp("^\\d*$");

        if (maxLength) {
          re = new RegExp(`^\\d{0,${maxLength}}$`);
        }

        if (!re.test(value)) {
          return;
        }
      }

      if (noZero && value === "0") {
        value = "";
      }

      if (maxChars && value.length > maxChars) {
        value = value.slice(0, maxChars);
      }

      onChange?.({
        ...event,
        target: {
          ...event.target,
          value,
        },
      });
    }

    const {
      error,
      inputClasses,
      value,
      formControlClasses,
      maxChars,
      ...rest
    } = props;

    return (
      <FormControl
        fullWidth
        error={Boolean(error)}
        margin="normal"
        variant="outlined"
        classes={{
          ...formControlClasses,
        }}
        className={clsx({
          "form-error": !!error,
        })}
      >
        <MaterialOutlinedInput
          data-test={name ? `${name}-field` : undefined}
          {...rest}
          ref={ref}
          value={value}
          fullWidth
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          classes={{
            root: defaultClasses.materialInputRoot,
            ...inputClasses,
          }}
        />
        {Boolean(error) && (
          <FormHelperText
            variant="standard"
            className={clsx(classes?.error, "error")}
          >
            {error}
          </FormHelperText>
        )}
        {maxChars !== undefined && typeof value === "string" && (
          <FormHelperText className={defaultClasses.charsCounter}>
            <Typography
              component="span"
              color={value.length > maxChars ? "error" : "inherit"}
            >
              {value.length}/{maxChars}
            </Typography>
          </FormHelperText>
        )}
      </FormControl>
    );
  },
);

OutlinedInputComponent.defaultProps = {
  onBlur: () => null,
  onChange: () => null,
  type: "text",
};
OutlinedInputComponent.displayName = "OutlinedInput";

export default OutlinedInputComponent;
