/* eslint-disable max-len */

import { keys as Homepage } from "./homepage";
import { keys as childcareService } from "./childcare_service";
import { keys as childcareControlPanel } from "./childcare_control_panel";
import { keys as common } from "./common";
import { keys as dashboard } from "./dashboard";

export const keys = {
  common,
  childcareControlPanel,
  childcareService,
  homepage: Homepage,
  dashboard,

  manageWeekendCarePage: {
    staffingEdit: {
      inputLabel: "No. of assigned teachers",
      tooltip:
        "The number of assigned teachers will impact service capacity, as our booking algorithm will not permit the number of attending kids to exceed mandated teacher:child ratios.",
    },

    eligibility: {
      preview: {
        all: "all families",
        "all-required-medical-forms": "all families",
        enrolled: "enrolled families only",
        "enrolled-and-network": "enrolled + network families",
        "care-pass": "parent pass",
        "care-pass-required-medical-forms": "parent pass",
      },
      title: {
        all: "All families",
        "all-required-medical-forms": "All families",
        enrolled: "Enrolled families",
        "enrolled-and-network": "Enrolled + Network families",
        "care-pass": "Parent Pass",
        "care-pass-required-medical-forms": "Parent Pass",
      },
    },

    activilities: {
      defaultSettingText:
        "kids can look forward to play areas tailored specifically for their age group, quiet spaces for learning and relaxing, as well as age specific developmental and enrichment activities.",
    },

    dropOff: {
      defaultSettingText: "сurbside drop-off with fever screening",
    },

    staff: {
      defaultSettingText:
        "our team is made up of childcare professionals with expertise in safety, hygiene, sanitation, first-aid and CPR procedures. We strictly adhere to all mandated teacher:child ratios and COVID-19 safety procedures",
    },

    pickUp: {
      defaultSettingText:
        "сurbside pick-up, with children released only to authorized adults",
    },

    meals: {
      defaultSettingText:
        "parents should pack and label all necessary snacks, meals and drinks.  Items with peanut butter or nuts are not allowed",
    },

    supplies: {
      defaultSettingText:
        "parents should pack an extra outfit, diapers, pull-ups, wipes, diaper-rash cream, crib sheet, blanket",
    },
  },

  deepDivePanel: {
    features: {
      military_fee_description:
        "Tuition assistance available for eligible active duty military families. For details, contact facility",
      military_fee_title: "Military discount",
      montessori_description:
        "Core components of a Montessori education include: 1. Montessori trained teachers 2. Montessori materials 3. multi-age classrooms 4. child-directed work 5. uninterrupted work period",
      naeyc_description:
        "NAEYC is a professional membership organization that works to promote high quality learning for young children",
      play_based_learning_description:
        "Children are encouraged to actively explore and connect with the world around them",
      play_based_learning_title: "Play-based learning",
      project_based_learning_description:
        "A learn-by-doing approach, whereby students are encouraged to take on and complete real-world tasks and projects",
      project_based_learning_title: "Project-based learning",
    },
  },
};
