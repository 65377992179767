import { createUseStyles } from "react-jss";

import { ITheme } from "@theme";

const styles = (theme: ITheme) => ({
  materialInputRoot: {
    "& input": {
      "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus":
        {
          "-webkit-box-shadow": `0 0 0 30px ${theme.palette.background.default} inset !important`,
          backgroundColor: `${theme.palette.background.default} !important`,
        },
    },
  },

  charsCounter: {
    textAlign: "right",
  },

  helperText: {
    color: theme.palette.primary.main,
  },
});

export default createUseStyles<ITheme, keyof ReturnType<typeof styles>>(
  styles,
  { name: "CustomInput" },
);
